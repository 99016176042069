import { BehaviorSubject } from "rxjs";
import Keycloak from "keycloak-js";
import axios from "axios";

export let keycloak: {
  instance?: Keycloak;
  loaded?: Promise<void>;
  tenantId?: string;
} = {};

export interface UserScopes {
  id: string;
  scope: string;
}

export const bootstrap = async (kcInit: {
  url: string;
  realm: string;
  clientId: string;
  baseUrl: string;
}) => {
  if (keycloak.instance) return;

  keycloak.instance = new Keycloak({
    ...kcInit,
  });
  const urlParams = new URLSearchParams(window.location.search);

  const token = urlParams.get("accessToken");
  const refreshToken = urlParams.get("refreshToken");
  const sessionId = urlParams.get("session_state");

  if (token && refreshToken && sessionId) {
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("sessionId", sessionId);
  }

  const tokenFromStorage = window.localStorage.getItem("token");
  const refreshTokenFromStorage = window.localStorage.getItem("refreshToken");
  const sessionIdFromStorage = window.localStorage.getItem("sessionId");

  keycloak.instance.token = tokenFromStorage;
  keycloak.instance.refreshToken = refreshTokenFromStorage;
  keycloak.instance.sessionId = sessionIdFromStorage;

  const authenticated = await keycloak.instance.init({
    onLoad: "check-sso",
  });

  keycloak.instance.onTokenExpired = () => {
    keycloak.instance
      .updateToken(5)
      .then((refreshed) => {
        if (refreshed) {
        } else {
          console.log(
            "Token not refreshed, valid for " +
              Math.round(
                keycloak.instance.tokenParsed.exp +
                  keycloak.instance.timeSkew -
                  new Date().getTime() / 1000
              ) +
              " seconds"
          );
        }
      })
      .catch(() => {
        console.log("Failed to refresh token");
      });
  };

  if (!keycloak.instance.token) await keycloak.instance.login();

  keycloak.tenantId = await axios
    .get(`${kcInit.baseUrl}/tenants`, {
      headers: {
        Authorization: `Bearer ${keycloak.instance.token}`,
      },
    })
    .then((res) => {
      return res.data[0].id;
    });
};

export const showMenu$ = new BehaviorSubject(true);
export const userScopes$ = new BehaviorSubject<UserScopes[]>([]);
